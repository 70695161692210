import React from "react";
import theme from "theme";
import { Theme, Link, Box, Text, Section, Image, LinkBox, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Giuseppe Pastore
			</title>
			<meta name={"description"} content={"Full stack software engineer"} />
			<meta property={"og:title"} content={"Giuseppe Pastore"} />
			<meta property={"og:description"} content={"Full stack software engineer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<meta name={"msapplication-TileColor"} content={"#30251F"} />
		</Helmet>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z) 50% 15%/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-primary"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text
							as="h4"
							margin="0"
							font="--base"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							About me
						</Text>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							Hey I’m Giuseppe
						</Text>
						<Text as="p" margin="16px 0" font="--base" max-width="400px">
							Hi there! I'm a passionate freelance software developer. However, I don't like the idea of being just a "technician." I always strive to be an integral part of the projects I work on, bringing forth all my skills and knowledge. I have experience working with various technologies, although I have a fondness for web technologies. In addition to my freelancer life, I am also one of the masterminds behind Gymnasio, a tech startup that combines AI and sports to create an extraordinary digital training experience. 
Have a look to my portfolio and to the{" "}
							<Link
								href="https://www.gymnasio.it"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Gymnasio
							</Link>
							{" "}website to have a look to some of my works and understand if I can help you.{" "}
							<br />
							<br />
							Feel free to reach out to me if you want to collaborate!
							<br />
							<br />
							<Box
								width="50%"
								padding="0.5vw 1vw 0.5vw 1vw"
								color="#fafdff"
								background="#fffefe"
								text-align="center"
								border-radius="5%"
							>
								<Link
									color="--primary"
									font="--headline3"
									hover-border-radius="100%"
									border-radius="100%"
									type="button"
									href="#contact_me"
									text-decoration-line="initial"
									text-align="center"
								>
									Contact me!
								</Link>
								{" "}
							</Box>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
				width="100%"
			>
				<Text
					font="--headline2"
					color="--light"
					text-align="center"
					as="h2"
					margin="16px 0px 0px 0px"
				>
					Some of my works
				</Text>
				<Text
					font="--headline3"
					color="--light"
					text-align="center"
					as="h3"
					margin="16px 0px 0px 0px"
				>
					Click to discover technologies
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column=" 1/ span 2"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						background="#000000"
						border-radius="24px"
						hover-transform="scale(1.04)"
						position="relative"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="#000000"
							display="flex"
							align-items="center"
							justify-content="space-around"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
							padding="5% 5% 5% 5%"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/atom.png?v=2023-05-21T12:42:28.994Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/aws.png?v=2023-05-21T12:45:47.723Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/neural-network.png?v=2023-05-21T12:47:01.680Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/gymnasioVideoShort.gif?v=2023-05-21T11:37:25.410Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							background="#000000"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Gymnasio AI Yoga Teacher
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							SaaS providing posture analysis, tailored advice and fitness assessments for a truly personalized practice in digital sport platforms.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column=" / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						background="#000046"
						border-radius="24px"
						hover-transform="scale(1.04)"
						position="relative"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="rgba(0, 0, 0, 0)"
							display="flex"
							align-items="center"
							justify-content="center"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
							flex-direction="column"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/csharp.png?v=2023-05-21T12:49:52.954Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/WPF.png?v=2023-05-21T10:19:06.535Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/HyperTennisLogoV.png?v=2023-05-18T12:46:41.326Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="contain"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							padding="5vw 5vw 5vw 5vw"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							WPF application optimization
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Tennis tracking system for athletes and schools
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column=" / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						background="#000046"
						border-radius="24px"
						hover-transform="scale(1.04)"
						position="relative"
						flex-direction="column"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="rgba(0, 0, 0, 0)"
							display="flex"
							align-items="center"
							justify-content="space-around"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
							flex-direction="column"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/atom.png?v=2023-05-21T12:42:28.994Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/aws.png?v=2023-05-21T12:45:47.723Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/identiTre.jpg?v=2023-05-18T12:18:49.320Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Event management CRM
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Responsive web app designed for managing events for a social association
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="2 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
					href="#"
					target="_blank"
				>
					<Box
						width="100%"
						align-items="center"
						display="block"
						justify-content="center"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						pointer-events="auto"
						as="div"
						hover-transform="scale(1.04)"
						hover-width="100%"
						position="relative"
						align-self="center"
						flex-direction="column"
						align-content="flex-start"
						text-align="center"
						flex="0 1 auto"
						background="rgba(0, 0, 0, 0)"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="rgba(0, 0, 0, 0)"
							display="flex"
							align-items="center"
							justify-content="center"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
							padding="5% 5% 5% 5%"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/wordpress.png?v=2023-05-19T14:19:22.453Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/videoAMin.gif?v=2023-05-18T10:49:54.562Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Landing page for #unononbasta campaign{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Campaign created by Visionary Days and Officine Italia to obtain mor investments for young people in the italian PNRR.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="center"
						display="block"
						justify-content="center"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						pointer-events="auto"
						as="div"
						hover-transform="scale(1.04)"
						hover-width="100%"
						position="relative"
						align-self="center"
						flex-direction="column"
						align-content="flex-start"
						text-align="center"
						flex="0 1 auto"
						background="rgba(0, 0, 0, 0)"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="rgba(0, 0, 0, 0)"
							display="flex"
							align-items="center"
							justify-content="space-around"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/atom.png?v=2023-05-21T12:42:28.994Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/aws.png?v=2023-05-21T12:45:47.723Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/library.gif?v=2023-05-19T15:49:04.743Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="contain"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Data management dashboard for plugin customization
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Desktop-only web application designed to customize branding, labels, and data presented in Gymnasio plugin
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="3 / span 1"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="center"
						display="block"
						justify-content="center"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						pointer-events="auto"
						as="div"
						hover-transform="scale(1.04)"
						hover-width="100%"
						position="relative"
						align-self="center"
						flex-direction="column"
						align-content="flex-start"
						text-align="center"
						flex="0 1 auto"
						background="#ffffff"
						color="#000000"
						border-radius="24px"
					>
						<Box
							min-width="100px"
							min-height="100px"
							position="absolute"
							width="100%"
							height="100%"
							background="rgba(0, 0, 0, 0)"
							display="flex"
							align-items="center"
							justify-content="space-around"
							align-content="center"
							hover-background="rgba(0, 0, 0, 0.79)"
							opacity="0"
							hover-opacity="1"
							flex-direction="column"
						>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/reactNative.png?v=2023-05-21T12:53:02.381Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
							/>
							<Image
								src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/aws.png?v=2023-05-21T12:45:47.723Z"
								display="block"
								height="30%"
								hover-opacity="1"
								opacity="1"
								border-radius="5px"
							/>
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/new_Home.png?v=2023-05-19T15:54:09.079Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="contain"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							React Native mobile app
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Data interface and controller for a system designed for a gym system where the device can monitor users' workouts
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 1"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
					grid-row="auto / auto"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="space-around"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						border-radius="24px"
						background="#ffffff"
						hover-transform="scale(1.04)"
						position="relative"
						flex-direction="column"
					>
						<Text
							margin="0px 0px 0px 0px"
							position="absolute"
							width="100%"
							height="100%"
							align-self="auto"
							text-align="center"
							background="rgba(0, 0, 0, 0)"
							color="#ffffff"
							flex="0 1 auto"
							padding="0px 0px 0px 0px"
							align-items="center"
							justify-content="center"
							hover-display="flex"
							hover-background="rgba(0, 0, 0, 0.79)"
							z-index="0"
							opacity="0"
							hover-opacity="1"
						>
							PROVA HOVR TEEXT
						</Text>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/identiTre.jpg?v=2023-05-18T12:18:49.320Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="contain"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Event management CRM
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Responsive web app designed for managing events for a social association
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="2 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="space-around"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
						background="#000000 radial-gradient(ellipse at center,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 19%,#092daf 83.3%)"
						border-radius="24px"
						padding="0px 20px 0px 20px"
						opacity="1"
						hover-transform="scale(1.04)"
						position="relative"
						left="0px"
					>
						<Box
							min-width="100px"
							min-height="100px"
							width="100%"
							height="100%"
							position="absolute"
							background="rgba(0, 0, 0, 0.79)"
							align-self="flex-start"
							display="flex"
							align-items="center"
							justify-content="space-around"
							flex-wrap="wrap"
							flex-direction="row"
							padding="5% 5% 5% 5%"
							left="0px"
							opacity="0"
							hover-opacity="1"
						>
							<Image src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/qt.png?v=2023-05-19T14:37:57.814Z" display="block" width="5vw" />
							<Image src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/c++.png?v=2023-05-19T14:40:28.159Z" display="block" width="5vw" />
							<Image src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/mongoDB.png?v=2023-05-19T14:42:25.549Z" display="block" width="5vw" />
							<Image src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/docker.webp?v=2023-05-19T14:43:23.968Z" display="block" width="5vw" />
						</Box>
						<Image
							src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/demo.gif?v=2023-05-16T07:36:52.394Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="contain"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							C
							<Strong>
								ollaborative real-time editor
							</Strong>
							{" "}written in C++ using Qt GUI framework.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Project based on a distributed data structure called Conflict-free Replicated Data Type (CRDT) and implements, in particular, LSEQ strategy.
						</Text>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="160px 0 50px 0"
			lg-padding="100px 0 50px 0"
			sm-padding="40px 0 40px 0"
			md-padding="40px 0 50px 0"
			display="none"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text font="--headline2" color="--secondary" margin="0px 0px 20px 0px" lg-font="normal 500 32px/1.3 &quot;Marcellus&quot;, serif">
					Real people. Real wedding stories.
				</Text>
				<Text
					lg-margin="0px 0px 40px 0px"
					font="--lead"
					color="--darkL2"
					margin="0px 0px 80px 0px"
					width="50%"
					lg-width="65%"
					sm-width="100%"
				>
					Enjoy our wedding portfolio where each wedding is as stunning and real as the emotions in the photos shot by one of our photographers.
				</Text>
				<Box
					empty-border-color="LightGray"
					display="flex"
					margin="0px 0px 64px 0px"
					md-flex-direction="column"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
				>
					<Box
						empty-border-color="LightGray"
						md-border-style="none"
						empty-min-height="64px"
						empty-border-width="1px"
						width="55%"
						margin="0px 74px 0px 0px"
						empty-border-style="solid"
						border-width="0px 0px 1px 0px "
						border-style="solid"
						md-width="100%"
						empty-min-width="64px"
						border-color="rgba(224, 89, 119, 0.5)"
						lg-margin="0px 32px 0px 0px"
						md-margin="0px 32px 30px 0px"
					>
						<Image
							max-height="288px"
							lg-min-height="308px"
							lg-max-height="none"
							md-margin="0px 0px 0px 0px"
							src="https://images.unsplash.com/photo-1604017011826-d3b4c23f8914?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
							width="100%"
							object-fit="cover"
							margin="0px 0px 40px 0px"
						/>
					</Box>
					<Box
						padding="0px 0px 0px 0px"
						border-width="0px 0px 1px 0px "
						border-style="solid"
						empty-min-height="64px"
						empty-border-color="LightGray"
						width="45%"
						border-color="rgba(224, 89, 119, 0.5)"
						md-width="100%"
						empty-min-width="64px"
						empty-border-width="1px"
						empty-border-style="solid"
					>
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--secondary">
							Alisia + Jacob
						</Text>
						<Text margin="0px 0px 16px 0px" font="--base" color="--coralD1">
							A dazzling wedding in Tampa
						</Text>
						<Text margin="0px 0px 40px 0px" font="--lead" color="--darkL2" lg-font="normal 400 16px/1.5 &quot;Marcellus&quot;, serif">
							It was so much fun.  Alicia and Jacob's love story is fantastic, and the admiration and excitement in their eyes at the wedding ceremony was clear for everyone to see.  They decided to start the ceremony with a beautiful song sung by the bride. It was so touching that I burst into tears and couldn't take my eyes off the bride and groom.  You could feel the excitement throughout the day, which made photographing it both emotional and rewarding.
						</Text>
					</Box>
				</Box>
				<Box
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					margin="0px 0px 64px 0px"
					md-flex-direction="column"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Box
						empty-border-width="1px"
						empty-border-style="solid"
						border-color="rgba(224, 89, 119, 0.5)"
						lg-margin="0px 32px 0px 0px"
						empty-border-color="LightGray"
						md-border-style="none"
						empty-min-width="64px"
						empty-min-height="64px"
						width="55%"
						border-width="0px 0px 1px 0px "
						md-width="100%"
						border-style="solid"
						margin="0px 74px 0px 0px"
						md-margin="0px 32px 30px 0px"
					>
						<Image
							src="https://images.unsplash.com/photo-1591604466107-ec97de577aff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
							width="100%"
							object-fit="cover"
							margin="0px 0px 40px 0px"
							max-height="288px"
							object-position="0% 0%"
							lg-min-height="308px"
							md-margin="0px 0px 0px 0px"
						/>
					</Box>
					<Box
						empty-border-color="LightGray"
						width="45%"
						border-width="0px 0px 1px 0px "
						border-style="solid"
						border-color="rgba(224, 89, 119, 0.5)"
						empty-min-width="64px"
						empty-min-height="64px"
						empty-border-width="1px"
						empty-border-style="solid"
						padding="0px 0px 0px 0px"
						md-width="100%"
					>
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--secondary">
							Ann + Robert
						</Text>
						<Text margin="0px 0px 16px 0px" font="--base" color="--coralD1">
							A vibrant and fascinating wedding in Orlando
						</Text>
						<Text color="--darkL2" lg-font="normal 400 16px/1.5 &quot;Marcellus&quot;, serif" margin="0px 0px 40px 0px" font="--lead">
							Ann and Robert's wedding day was well-thought-out, and my favorite detail was Robert's present to his bride.  They had to be apart for a while dealing with some issues in separate countries. During that month, Robert wrote a letter to Ann every day.  Right after Ann had finished getting dressed on the morning of the wedding, Robert's mother brought a pile of those letters to her.  Ann's reaction to the gift was unforgettable and the photos capturing that moment are amazing!
						</Text>
					</Box>
				</Box>
				<Box
					md-flex-direction="column"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					margin="0px 0px 0px 0px"
				>
					<Box
						empty-min-width="64px"
						empty-border-color="LightGray"
						border-color="rgba(224, 89, 119, 0.5)"
						md-width="100%"
						empty-min-height="64px"
						empty-border-width="1px"
						empty-border-style="solid"
						width="55%"
						border-width="0px 0px 1px 0px "
						border-style="solid"
						margin="0px 74px 0px 0px"
						lg-margin="0px 32px 0px 0px"
						md-margin="0px 32px 30px 0px"
						md-border-style="none"
					>
						<Image
							width="100%"
							object-fit="cover"
							margin="0px 0px 40px 0px"
							max-height="288px"
							object-position="0% 0%"
							lg-min-height="308px"
							md-margin="0px 0px 0px 0px"
							src="https://images.unsplash.com/flagged/photo-1620830102229-9db5c00d4afc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80"
						/>
					</Box>
					<Box
						empty-border-width="1px"
						empty-border-style="solid"
						border-style="solid"
						md-width="100%"
						padding="0px 0px 0px 0px"
						border-width="0px 0px 1px 0px "
						border-color="rgba(224, 89, 119, 0.5)"
						empty-min-width="64px"
						empty-min-height="64px"
						empty-border-color="LightGray"
						width="45%"
					>
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--secondary">
							Gloria + Max
						</Text>
						<Text color="--coralD1" margin="0px 0px 16px 0px" font="--base">
							An elegant backyard wedding in Miami
						</Text>
						<Text margin="0px 0px 40px 0px" font="--lead" color="--darkL2" lg-font="normal 400 16px/1.5 &quot;Marcellus&quot;, serif">
							All of the elements that are unique to the bride and groom make a wedding special to me. In the beginning, Gloria and Max's wedding reception was supposed to be at a different place, but because of a hurricane, they had to change it and ended up holding the reception at Gloria's parents' house. The house was absolutely stunning. And in my opinion, it was a perfect place to celebrate their marriage!
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 100px 0">
			<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
				<Text
					sm-text-align="center"
					sm-font="normal 500 28px/1.3 &quot;Marcellus&quot;, serif"
					md-font="normal 500 28px/1.3 &quot;Marcellus&quot;, serif"
					margin="0px 0px 20px 0px"
					font="--headline2"
					color="--primary"
					max-width="60%"
				>
					Want to collaborate?
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--darkL2"
					max-width="60%"
					lg-max-width="500px"
					md-font="normal 400 16px/1.5 &quot;Marcellus&quot;, serif"
				>
					Every project is unique, and I'm dedicated to helping you bring your vision to life. Whether you're looking to enhance user experience, optimize performance, or create your project from scratch, I'll provide the expertise and assistance you need.
					<br />
					<br />
					Feel free to get in touch using the contact form below. I'm excited to hear about your project and collaborate with you!
				</Text>
				<Box border-radius="100%" background="inherit" position="relative">
					<Link
						id="contact_me"
						hover-opacity=".8"
						transform="matrix(1.1, 0.22, -0.14, 1, 0, 0)"
						color="--primary"
						padding="30px 64px 30px 64px"
						font="--headline3"
						hover-border-radius="100%"
						border-radius="100%"
						background="rgba(0, 119, 204, 0)"
						type="button"
						href="mailto:giuseppe.pastore1017@gmail.com?Proposal of collaboration"
						target="_blank"
						margin="0px 0px 0px 0px"
						border-color="#5757e2"
						text-decoration-line="initial"
					>
						Contact me
					</Link>
					{" "}
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"615c316435c5c8001f775566"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n    scroll-behavior: smooth;\n}\n"}
			</style>
		</RawHtml>
	</Theme>;
});