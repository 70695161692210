export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6461da2f0343c10023971539",
				"6461da2f0343c1002397153d"
			]
		},
		"6461da2f0343c10023971539": {
			"id": "6461da2f0343c10023971539",
			"name": "404",
			"pageUrl": "404"
		},
		"6461da2f0343c1002397153d": {
			"id": "6461da2f0343c1002397153d",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "--color-pageBg"
		},
		"seo": {
			"og:description": "Full stack software engineer",
			"og:title": "Giuseppe Pastore",
			"favicon_32px": "https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z",
			"favicon_apple_152px": "https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z",
			"description": "Full stack software engineer",
			"og:image": "https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z",
			"background_win10": "#30251F",
			"title": "Giuseppe Pastore"
		}
	}
}