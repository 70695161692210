import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, LinkBox, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Giuseppe Pastore
			</title>
			<meta name={"description"} content={"Full stack software engineer"} />
			<meta property={"og:title"} content={"Giuseppe Pastore"} />
			<meta property={"og:description"} content={"Full stack software engineer"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"} />
			<meta name={"msapplication-TileColor"} content={"#30251F"} />
		</Helmet>
		<Section padding="68px 0 68px 0" md-padding="0px 0 28px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-direction="column" md-position="relative" />
			<Box
				display="flex"
				flex-direction="column"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				md-align-items="center"
				width="50%"
				align-items="flex-start"
				justify-content="center"
				md-width="100%"
				empty-min-height="64px"
				lg-margin="0px 0px 0px 0px"
				md-position="relative"
				md-margin="0px 0px 30px 0px"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 40px 0px"
					font="--headline1"
					color="--primary"
					lg-font="normal 500 50px/1.3 &quot;Marcellus&quot;, serif"
					lg-margin="0px 0px 16px 0px"
					md-font="normal 500 40px/1.3 &quot;Marcellus&quot;, serif"
					md-text-align="center"
				>
					404.
					<br />
					That’s an error.{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 60px 0px"
					font="--lead"
					color="--grey"
					md-font="normal 400 16px/1.5 &quot;Faustina&quot;, serif"
					md-margin="0px 0px 32px 0px"
				>
					The requested URL was not found on this server.{"\n\n"}
				</Text>
				<LinkBox
					position="relative"
					hover-color="--brown"
					padding="54px 54px 54px 54px"
					width="255px"
					href="/"
					md-bottom="0px"
					border-color="--color-secondary"
					align-items="center"
					color="--secondary"
					hover-background="--color-secondary"
					transform="matrix(1, -0.17, 0.08, 0.99, 0, 0)"
					md-position="static"
					border-radius="500%"
				>
					<Box
						height="100%"
						width="100%"
						position="absolute"
						border-radius="100%"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
					/>
					<Box
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
						transform="matrix(0.99, -0.15, 0.9, 0.9, 0, 0)"
						height="100%"
						width="100%"
						position="absolute"
						border-radius="100%"
					/>
					<Box
						border-radius="100%"
						border-width="1px"
						border-style="solid"
						border-color="--color-secondary"
						transform="matrix(0.99, -0.1, 0.9, 0.9, 0, 0)"
						height="100%"
						width="100%"
						position="absolute"
					/>
					<Text
						transform="matrix(1, 0.17, -0.1, 1, 0, 0)"
						margin="0px 0px 0px 0px"
						padding="58px 30px 40px 30px"
						text-align="center"
						left="0px"
						bottom="auto"
						right="auto"
						font="--headline3"
						height="100%"
						width="100%"
						position="absolute"
						top="-15px"
					>
						GO BACK
					</Text>
				</LinkBox>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				empty-min-width="64px"
				width="50%"
				margin="0px 0px 0px 60px"
				md-width="100%"
				md-margin="0px 0px 0px 0px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6461da2f0343c1002397151b/images/me.jpeg?v=2023-05-15T08:06:17.591Z"
					max-height="616px"
					width="100%"
					object-fit="cover"
					object-position="0% 70%"
					lg-min-height="481px"
					md-max-height="410px"
					md-min-height="none"
				/>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"615c316435c5c8001f775566"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n    scroll-behavior: smooth;\n}\n"}
			</style>
		</RawHtml>
	</Theme>;
});